import React, { useState } from 'react';
import CustomTabs from '../CustomTabs';
import { Transaction_Tab } from '../../utils/constants';

const RetDbTransactionTab = ({ setQuery, setTabQueryreset }) => {
  const [value, setValue] = useState(0);

  const tabs = [
    { label: 'ALL' },
    { label: 'Prepaid' },
    { label: 'Dth' },
    { label: 'Utility' },
    { label: 'Verification' },
    { label: 'Money Transfer' },
    { label: 'Collections' },
    { label: 'IRCTC' },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const selectedTab = Transaction_Tab[newValue];

    // Trigger the reset and update the query
    setTabQueryreset(true);  // Signal to reset filters

    // Delay the query update slightly to ensure the reset happens first
    setTimeout(() => {
      setQuery(`category=${selectedTab}`);
      setTabQueryreset(false); // After updating the query, allow further resets
    }, 0);
  };

  return (
    <CustomTabs
      tabs={tabs}
      value={value}
      onChange={handleChange}
      
    />
  );
};

export default RetDbTransactionTab;
