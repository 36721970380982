import React, { useState } from 'react';
import CustomTabs from '../component/CustomTabs';
import UPITransferView from './UPITransferView';
import NepalTransfer from "./NepalTransfer";
import VendorPayments from './VendorPayments';
import MoneyTransferView from './MoneyTransferView';
import { mt_tab_value } from '../utils/constants';


export default function MoneyTransferViewNew() {
 
  const [value, setValue] = useState(0);
const [currentType, setCurrentType] = useState()
  const tabs = [
    { label: "DMT1", content: <MoneyTransferView type={currentType} /> },
    { label: "DMT2", content: <MoneyTransferView type={currentType} />  },
    { label: "Vendor Payments", content:<VendorPayments type={currentType}/>,},
    { label: "Super", content:<VendorPayments type={currentType} /> },
    { label: "Nepal", content:<NepalTransfer type={currentType} /> },
    { label: "Upi", content: <UPITransferView  type={currentType} /> },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentType(mt_tab_value[newValue])
  };
console.log("type",currentType);
  return (
    <CustomTabs
      tabs={tabs}
      value={value}
      onChange={handleChange}

    />
  );
}
