import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import RetTxnCardComponent from "./RetTxnCardComponent";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { primaryColor, getHoverInActive } from "../theme/setThemeColor";
import Spinner from "../commons/Spinner";

// tab styles . .  .
export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    variant="scrollable" // Ensures tabs are scrollable on smaller screens
    scrollButtons="auto" // Shows scroll buttons automatically when needed
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  padding: "8px 6px", // Reduced padding
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 80,
    width: "0px",
    backgroundColor: "#ffffff",
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  () => ({
    color: "#fff",
    fontSize: "12px",
    minHeight: "15px",
    minWidth: "25px",
    padding: "8px 6px", // Reduced padding
    borderRadius: "4px",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: `hsla(0,0%,100%,.2)`,
      transition: `background-color .3s .2s`,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const TodayThisLastComponent = ({
  txnDataDuration,
  txnDataReq,
  txnData,
  getTxnData,
  handleChange,
}) => {
  return (
    <div>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop:"-1.5%",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        
      </Grid>

      {/* filter tabs component */}
      <Grid
        container
        alignItems="center"
      >
        {/* First Grid with Tabs */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            width: "100%",
            color: "black",
          }}
        >
          <StyledTabs
            value={txnDataDuration || "TODAY"}
            onChange={handleChange}
            indicatorColor="secondary"
            aria-label="full width tabs example"
          >
            <StyledTab
              label="TODAY"
              value="TODAY"
              sx={{
                color: "black",
                fontWeight: "bold",
                textTransform: "none",
                backgroundColor: "transparent",
                '&.Mui-selected': {
                  backgroundColor: "#007bff",
                  color: "white",
                },
                '&:hover': {
                  backgroundColor: "#e7f0ff",
                  color: "black",
                },
              }}
            />
            <StyledTab
              label="THIS"
              value="THIS"
              sx={{
                color: "black",
                fontWeight: "bold",
                textTransform: "none",
                backgroundColor: "transparent",
                '&.Mui-selected': {
                  backgroundColor: "#28a745",
                  color: "white",
                },
                '&:hover': {
                  backgroundColor: "#d4edda",
                  color: "black",
                },
              }}
            />
            <StyledTab
              label="LAST"
              value="LAST"
              sx={{
                color: "black",
                fontWeight: "bold",
                textTransform: "none",
                backgroundColor: "transparent",
                '&.Mui-selected': {
                  backgroundColor: "#6c757d",
                  color: "white",
                },
                '&:hover': {
                  backgroundColor: "#f1f3f5",
                  color: "black",
                },
              }}
            />
          </StyledTabs>
        </Grid>

        {/* Second Grid with Marquee */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "flex-start" },
            paddingLeft: { md: "1rem" },
            marginTop: { xs: "1rem", md: "0" },
          }}
        >
          <marquee behavior="scroll" direction="left">
            new section
          </marquee>
        </Grid>
      </Grid>

      {/* success fail cards mapping */}
      <Grid
        container
        sx={{
          alignItems: "center",
          marginBottom: "1rem",
          flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" },
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {txnDataReq && <Spinner loading={txnDataReq} circleBlue />}
        {txnData?.length > 0 &&
          txnData.map((item, index) => (
            <Box
              key={index}
              sx={{
                mt: -1.5,
                width: { xs: "100%", sm: "100%", md: "100%", lg: "calc(25% - 16px)" },
                maxWidth: "100%",
                boxSizing: "border-box",
                flexGrow: 1,
              }}
            >
              <RetTxnCardComponent item={item} />
            </Box>
          ))}
      </Grid>
    </div>
  );
};

export default TodayThisLastComponent;
