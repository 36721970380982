import { Box, IconButton, Tooltip,Grid } from "@mui/material";
import React from "react";
// import ApiPaginate from "../component/ApiPaginate";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { massegetable } from "../component/CustomStyle";
import { datemonthYear } from "../utils/DateUtils";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import FilterCard from "../modals/FilterCard";
import useCommonContext from "../store/CommonContext";
let refresh;
function refreshFunc(setQueryParams) {
  setQueryParams("");
  if (refresh) refresh();
}
const AdminInMassegeView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();

  const [isShowFilterCard, setIsShowFilterCard] = useState(false);
  const {
    setChooseInitialCategoryFilter,
    chooseInitialCategoryFilter,
    refreshUser,
  } = useCommonContext();
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "70px",
    },
    {
      name: "Date",
      selector: (row) => datemonthYear(row.created_at),
      width: "100px",
    },
    {
      name: "From",
      selector: (row) => row.sender,
      width: "100px",
    },
    {
      name: "Massege",
      selector: (row) => (
        <div
          style={{
            whiteSpace: "break-spaces",
            overflow: "hidden",
            textOverflow: "clip",
            textAlign: "left",
          }}
        >
          {row.msg}
        </div>
      ),
    },
  ];

  const searchOptions = [{ field: "Sender", parameter: "sender" }];

  return (
    <Box>
    <Grid container alignItems="center" justifyContent="space-between">
   
      <Grid item sx={{width:"90%"}}>
        <FilterCard
          ifdateFilter
          ifFromBankFilter
          ifMessageFilter
          setQuery={setQuery}
          query={query}
          chooseInitialCategoryFilter={chooseInitialCategoryFilter !== "ALL" ? chooseInitialCategoryFilter : false}
          refresh={refresh}
          isShowFilterCard={isShowFilterCard}
          setIsShowFilterCard={setIsShowFilterCard}
        />
      </Grid>
  
     
      <Grid item>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title="refresh">
            <IconButton
              aria-label="refresh"
              sx={{ color: '#0F52BA' }}
              onClick={() => refreshFunc(setQuery)}
            >
              <CachedIcon className="refresh-purple" />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  
    <Grid>
      <div>
        <ApiPaginateSearch
          apiEnd={ApiEndpoints.GET_MASSEGE}
          searchOptions={searchOptions}
          setQuery={setQuery}
          query={query}
          columns={columns}
          showSearch={true}
          apiData={apiData}
          tableStyle={massegetable}
          setApiData={setApiData}
          ExpandedComponent=""
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
        />
      </div>
    </Grid>
  </Box>
  

  );
};

export default AdminInMassegeView;
