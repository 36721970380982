import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Typography,
  Tooltip,
  Container,
  IconButton,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";

import ApiPaginateSearch from "../component/ApiPaginateSearch";
import { useState } from "react";
import { CustomStyles } from "../component/CustomStyle";
import ApiEndpoints from "../network/ApiEndPoints";
import { datemonthYear } from "../utils/DateUtils";
import IssueHandlerModal from "../modals/IssueHandlerModal";
import CachedIcon from "@mui/icons-material/Cached";

import { currencySetter } from "../utils/Currencyutil";

import FilterCard from "../modals/FilterCard";

let refresh;
let refreshFilter;

function refreshFunc(setQuery) {
  setQuery(`status=OPEN`);
  if (refresh) refresh();
}

const ComplaintsView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();

  useEffect(() => {
    if (!query) setQuery("status=OPEN");
    return () => {};
  }, [query]);

  const columns = [
    {
      name: "Date",
      selector: (row) => (
        <div>
          <div>{datemonthYear(row.created_at)}</div>
          <div>{datemonthYear(row.updated_at)}</div>
        </div>
      ),
      width: "120px",
    },
    {
      name: "Est",
      selector: (row) => (
        <Tooltip title={row.establishment}>
          <div style={{ textAlign: "left", fontSize: "12px" }}>
            {row.establishment}
          </div>
        </Tooltip>
      ),
      width: "150px",

      wrap: true,
    },
    {
      name: "Operator/Route",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>
          <div style={{ fontSize: "13px", marginBottom: "5px" }}>
            {row.operator}
          </div>
          <div
            style={{ fontSize: "10px", fontWeight: "500", color: "#535353" }}
          >
            {row.route}
          </div>
        </div>
      ),
      center: false,
      width: "120px",
      wrap: true,
    },
    {
      name: "Number",
      selector: (row) => row.number,
      width: "90px",
    },
    {
      name: "Amount",
      selector: (row) => currencySetter(row.amount),
      width: "90px",
    },
    // {
    //   name: "Txn Date",
    //   selector: (row) =>
    //     row.txn_date && datemonthYear(row.txn_date)
    //       ? row.txn_date && datemonthYear(row.txn_date)
    //       : "13/14/2025",
    //   width: "85px",
    // },
    {
      name: "Txn ID / Txn Date",
      selector: (row) => (
        <Box sx={{ cursor: "pointer" }}>
          <div style={{ textAlign: "left", marginBottom: "5px" }}>
            {row.txnId}
          </div>
          <Tooltip title="Txn Date">
            <div className="light-text" style={{ textAlign: "left" }}>
              {row.txn_date ? row.txn_date : "NA"}
            </div>
          </Tooltip>
        </Box>
      ),
      width: "150px",
    },

    {
      name: "Message",
      cell: (row) => (
        <div style={{ textAlign: "left", fontSize: "11px" }}>{row.msg}</div>
      ),
      width: "150px",
      wrap: true,
    },
    {
      name: "Handler",
      cell: (row) => (
        <div style={{ textAlign: "left", fontSize: "11px" }}>
          {" "}
          <Tooltip title={row.handler ? row.handler : "NA"}>
            <Typography variant="div" className="light-text">
              {row.handler ? row.handler : "NA"}
            </Typography>
          </Tooltip>
        </div>
      ),
      width: "70px",
      wrap: true,
    },

    {
      name: "Remark",
      selector: (row) => (
        <div style={{ textAlign: "left", fontSize: "12px" }}>
          {row.remark ? row.remark : "NA"}
        </div>
      ),
      wrap: true,
      width: "130px",
    },
    {
      name: "Txn Status",
      selector: (row) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Box
            className="px-2"
            sx={{
              color: "#fff",

              backgroundColor:
                row.status && row.status === "SUCCESS"
                  ? "#00BF78"
                  : row.status && row.status === "PENDING"
                  ? "#F08D17"
                  : row.status && row.status === "FAILED"
                  ? "#DC6F6F"
                  : "#00BF78",
              borderRadius: "4px",
              fontSize: "14px",
              width: "71px",
              py: 0.5,
              mb: 1,
            }}
          >
            {row.status && row.status === "SUCCESS"
              ? "Success"
              : row.status && row.status === "PENDING"
              ? "Pending"
              : row.status && row.status === "FAILED"
              ? "Failed"
              : "Success"}
          </Box>
        </Box>
      ),
      wrap: true,
      width: "95px",
    },
    {
      name: "Status",
      selector: (row) => (
        <Box
          className="px-2"
          sx={{
            color: "#fff",
            backgroundColor:
              row.status && row.status === "OPEN" ? "#F08D17" : "#00BF78",
            borderRadius: "4px",
            fontSize: "13px",
            width: "71px",
            py: 0.5,
            mb: 1,
          }}
        >
          {row.status && row.status === "OPEN" ? "OPEN" : "CLOSED"}
        </Box>
      ),
      wrap: true,
      width: "90px",
    },
    // <FormControl className="customized-textfield" fullWidth>
    //   <TextField
    //     select
    //     value={defaultStatus && defaultStatus}
    //     onChange={(event) => {
    //       handleChangeStatus(event);
    //     }}
    //     sx={{ color: "#fff" }}
    //   >
    //     <MenuItem dense value="OPEN">
    //       OPEN
    //     </MenuItem>
    //     <MenuItem dense value="CLOSED">
    //       CLOSED
    //     </MenuItem>
    //   </TextField>
    // </FormControl>

    {
      name: "Action",
      selector: (row) => <IssueHandlerModal row={row} refresh={refresh} />,
      width: "60px",
    },
  ];

  return (
    <>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: { md: "none", sm: "none", xs: "flex" },
              justifyContent: "end",
              alignItems: "center",
              flexDirection: { md: "row" },
            }}
          ></Grid>

          <Grid item md={12} sm={12} xs={12}>
            <ApiPaginateSearch
              showSearch={false}
              // actionButtons={
              //   <Grid
              //     item
              //     md={12}
              //     sm={12}
              //     xs={12}
              //     sx={{
              //       display: "flex",
              //       justifyContent: { md: "end", xs: "start" },
              //       alignItems: "center",
              //       pr: 2,
              //       mt: { md: 0, xs: 2, sm: 2 },
              //     }}
              //   >
              //     {/* <RefreshComponent
              //       className="refresh-icon-table"
              //       onClick={() => {
              //         refresh();
              //       }}
              //     /> */}
              //   </Grid>
              // }
              apiEnd={ApiEndpoints.COMPLAINTS}
              setQuery={setQuery}
              columns={columns}
              apiData={apiData}
              setApiData={setApiData}
              tableStyle={CustomStyles}
              queryParam={query ? query : ""}
              returnRefetch={(ref) => {
                refresh = ref;
              }}
              isFilterAllowed={true}
              filterComponent={
                <FilterCard
                  showSearch={false}
                  ifComplaintStatus
                  setQuery={setQuery}
                  query={query}
                  clearHookCb={(cb) => {
                    refreshFilter = cb;
                  }}
                  refresh={refresh}
                  actionButtons={
                    <>
                    <Box sx={{justifyContent:"end"}}>
                     <Tooltip title="refresh">
          <IconButton
            aria-label="refresh"
            // color="success"
            sx={{
              color:"#0F52BA",
              mt:3.4
              ,ml:-4
            }}
            onClick={() => {
              refreshFunc(setQuery);
            }}
          >
            <CachedIcon className="refresh-purple" />
          </IconButton>
        </Tooltip>
        </Box>
                    </>
                  }
                />
              }
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ComplaintsView;
