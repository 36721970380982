import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { CustomStyles } from "../component/CustomStyle";
import BlockUnBlockPlans from "../modals/BlockUnBlockPlans";
import AddPlanModal from "../modals/AddPlanModal";
import DeletePlan from "../modals/DeletePlan";
import EditPlanModal from "../modals/EditPlanmodal";
import { currencySetter } from "../utils/Currencyutil";
import ApiPaginate from "../component/ApiPaginate";
import FilterCard from "../modals/FilterCard";
import useCommonContext from "../store/CommonContext";
import ApiEndpoints from "../network/ApiEndPoints";

let refresh;

function refreshFunc(setQueryParams) {
  setQueryParams("");
  if (refresh) refresh();
}

const AdminPlanView = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();

  const handleChangeOperator = (event) => {
    if (event.target.value !== "Operators") {
      setQuery(`operator=${event.target.value}`);
    }
  };

  const [isShowFilterCard, setIsShowFilterCard] = useState(false);
  const {
    setChooseInitialCategoryFilter,
    chooseInitialCategoryFilter,
    refreshUser,
  } = useCommonContext();

  const columns = [
    {
      name: "Operator",
      selector: (row) => row.operator,
      width: "150px",
    },
    {
      name: "Plan",
      selector: (row) => currencySetter(row.plan),
      width: "150px",
    },
    {
      name: "Validity",
      selector: (row) => row.validity,
      width: "150px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "650px",
    },
    // {
    //   name: "Status",
    //   selector: (row) =>  />,
    //   width: "150px",
    // },
    {
      name: "Action",
      selector: (row) => (
        <div style={{ display: "flex" }}>
          <BlockUnBlockPlans row={row}/>
          <EditPlanModal row={row} refresh={refresh} />
          <DeletePlan row={row} refresh={refresh} />
        </div>
      ),
      width: "150px",
    },
  ];

  return (
    <Box sx={{ p: 1 }}>
    <Grid 
      // container 
      spacing={3} 
      alignItems="center" 
      sx={{ flexWrap: 'nowrap', justifyContent: 'space-between'}}
    >
      
      <Grid item sx={{mb:"5px"}}>
        <FilterCard 
          ifOperatorFilter
          ifPlanFilter
          setQuery={setQuery}
          query={query}
          chooseInitialCategoryFilter={
            chooseInitialCategoryFilter !== "ALL"
              ? chooseInitialCategoryFilter
              : false
          }
          refresh={refresh}
          isShowFilterCard={isShowFilterCard}
          setIsShowFilterCard={setIsShowFilterCard}
        />
      </Grid>
  
     
      <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap:2,mt:"-40px"}}>
        <AddPlanModal refresh={refresh} />
        <Tooltip title="refresh">
          <IconButton
            aria-label="refresh"
            sx={{ color: "#0F52BA" }}
            onClick={() => {
              refreshFunc(setQuery);
            }}
          >
            <CachedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  
    <ApiPaginate
      apiEnd={ApiEndpoints.GET_PLANS}
      columns={columns}
      apiData={apiData}
      tableStyle={CustomStyles}
      setApiData={setApiData}
      queryParam={query ? query : ""}
      setQuery={setQuery}
      ExpandedComponent={null}
      returnRefetch={(ref) => {
        refresh = ref;
      }}
    />
  </Box>
  
  
  );
};

export default AdminPlanView;
