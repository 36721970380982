import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { get } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast } from "../utils/ToastUtil";
import Spinner from "../commons/Spinner";
import ModalHeader from "./ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChangeApiUsers,
  handleChangeKeys,
  resetData,
  setApiUserCharges,
  setApiUserKeys,
  updateApiUserCharges,
  updateApiUserKeys,
} from "../features/allUsers/allUsersSlice";
import { getTableHeadRowColor } from "../theme/setThemeColor";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "800px",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  fontFamily: "Poppins",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
};

const headerStyle = {
  position: "sticky",
  top: 0,
  color: "#fff", // White text color for contrast
  zIndex: 10,
  borderBottom: "1px solid #ddd",
};

const bodyStyle = {
  flex: 1,
  overflowY: "auto",
  padding: "16px",
};

const editableCellStyle = {
  "& .MuiInputBase-input": {
    textAlign: "center",
  },
};

const tableHeadStyle = {
  border: "none",
  color: "#fff",
  backgroundColor: getTableHeadRowColor(),
  fontFamily: "Poppins",
  paddingLeft: "8px",
  minHeight: "37px",
  maxHeight: "37px",
  borderBottom: "0.5px solid #DBDDDF",
  paddingBottom: "4px",
  paddingTop: "4px", // Reduced padding
};

const AdminChargesForApiUsers = ({ row }) => {
  const [request, setRequest] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { apiUserCharges, apiUserKeys } = useSelector((store) => store.allUsers);
  const { ip, hkey } = apiUserKeys;

  const [formValues, setFormValues] = useState({
    AIR: "",
    VOD: "",
    JIO: "",
    MTT: "",
    BST: "",
    ADG: "",
    DSH: "",
    D2H: "",
    TSK: "",
    SUN: "",
    ACV: "",
    DMT: "",
    DMT2: "",
    CCB: "",
    WTR: "",
    aeps1: "",
    aeps2: "",
    payout1: "",
    payout2: "",
    payout3: "",
    payout4: "",
  });

  useEffect(() => {
    if (apiUserCharges) {
      setFormValues((prevValues) => ({
        ...prevValues,
        ...apiUserCharges,
      }));
    }
  }, [apiUserCharges]);

  const getApiUsersCharges = () => {
    get(
      ApiEndpoints.API_USERS_CHARGES,
      `user_id=${row.id}`,
      setRequest,
      (res) => {
        const data = res?.data?.data;
        dispatch(setApiUserCharges(data));
        setOpen(true);
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getApiUsersKeys = () => {
    get(
      ApiEndpoints.API_USERS_KEYS,
      `user_id=${row.id}`,
      setRequest,
      (res) => {
        const data = res?.data?.data;
        dispatch(setApiUserKeys(data));
        setOpen(true);
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const handleOpen = () => {
    getApiUsersCharges();
    getApiUsersKeys();
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(resetData());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateApiUserCharges({ apiUserCharges, id: row.id }));
  };

  const handleSubmitKeys = (e) => {
    e.preventDefault();
    dispatch(updateApiUserKeys({ apiUserKeys, id: row.id }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValues({ ...formValues, [name]: value });
    dispatch(handleChangeApiUsers({ name, value }));
  };

  const handleChangeKey = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChangeKeys({ name, value }));
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      <Tooltip title="Edit Charges & Keys">
        <IconButton
          variant="contained"
          style={{ fontSize: "10px", marginLeft: "5px", color: "#e57373" }}
          onClick={handleOpen}
        >
          <Icon icon="fluent:document-percent-24-regular" style={{ fontSize: "24px" }} />
        </IconButton>
      </Tooltip>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Box sx={headerStyle}>
            <ModalHeader title="Edit Charges" handleClose={handleClose} />
          </Box>
          <Box sx={bodyStyle}>
            <Spinner loading={request} />

            {/* Charges Form */}
            <Box
              component="form"
              id="edit-charges"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Typography sx={{ fontSize: "25px", color: "black", mb: 2 }}>Charges</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeadStyle}>Operator</TableCell>
                      <TableCell sx={tableHeadStyle}>Charges</TableCell>
                      <TableCell sx={tableHeadStyle}>Operator</TableCell>
                      <TableCell sx={tableHeadStyle}>Charges</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(formValues)
                      .reduce((rows, key, index) => {
                        if (index % 2 === 0) {
                          rows.push([key]);
                        } else {
                          rows[rows.length - 1].push(key);
                        }
                        return rows;
                      }, [])
                      .map((row, index) => (
                        <TableRow key={index}>
                          {/* First operator and charges */}
                          <TableCell>{row[0]?.toUpperCase()}</TableCell>
                          <TableCell>
                            <TextField
                              name={row[0]}
                              value={formValues[row[0]] || ""}
                              onChange={handleChange}
                              size="small"
                              variant="standard"
                              type="number"
                              sx={editableCellStyle}
                            />
                          </TableCell>

                          {/* Second operator and charges, if exists */}
                          {row[1] ? (
                            <>
                              <TableCell>{row[1]?.toUpperCase()}</TableCell>
                              <TableCell>
                                <TextField
                                  name={row[1]}
                                  value={formValues[row[1]] || ""}
                                  onChange={handleChange}
                                  size="small"
                                  variant="standard"
                                  type="number"
                                  sx={editableCellStyle}
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell />
                              <TableCell />
                            </>
                          )}
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                        <Button type="submit" variant="contained" color="primary">
                          Submit
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {/* API Keys Form */}
            {/* <Box
              component="form"
              id="edit-keys"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmitKeys}
              sx={{ mt: 3 }}
            >
              <Typography sx={{ fontSize: "15px", color: "black", mb: 2 }}>API Key</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeadStyle}>Field</TableCell>
                      <TableCell sx={tableHeadStyle}>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>IP</TableCell>
                      <TableCell>
                        <TextField
                          name="ip"
                          value={ip || ""}
                          onChange={handleChangeKey}
                          size="small"
                          variant="standard"
                          type="text"
                          sx={editableCellStyle}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Key</TableCell>
                      <TableCell>
                        <TextField
                          name="hkey"
                          value={hkey || ""}
                          onChange={handleChangeKey}
                          size="small"
                          variant="standard"
                          type="text"
                          sx={editableCellStyle}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                        <Button type="submit" variant="contained" color="primary">
                          Submit
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box> */}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminChargesForApiUsers;
